document.addEventListener("DOMContentLoaded", function() {
    const headlines = document.querySelectorAll(".headline");

    headlines.forEach((headline) => {
        const lineHeight = parseInt(window.getComputedStyle(headline).lineHeight, 10);
        const maxHeight = lineHeight * 3;

        if (headline.scrollHeight > maxHeight) {
            headline.style.fontSize = "25px";
        }
    });
});

